<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="(기간)조치완료 요청일"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            :rejectItems="['ITT0000145']"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="업무"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 요청부서 -->
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="요청부서" 
            name="improveRequestDeptCd" 
            v-model="searchParam.improveRequestDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 조치부서 -->
          <c-dept 
            type="search" 
            label="조치부서" 
            name="actionDeptCd" 
            v-model="searchParam.actionDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="IBM_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmClassCd"
            label="개선구분"
            v-model="searchParam.ibmClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="지연된 업무 확인"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="개선 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            :disabled="!grid.data || grid.data.length === 0"
            label="개선결과보고서" 
            icon="print"
            @btnClicked="imprResultPrint" />
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="addEtcImpr" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='actionCompleteRequestDate'">
          <span :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
            {{props.row.actionCompleteRequestDate}}
            <!-- completeDateOver: {{props.row.completeDateOver}}
            ibmStepCd: {{props.row.ibmStepCd}}
            approvalStatusCd: {{props.row.approvalStatusCd}} -->
            <q-badge v-if="props.row.completeDateOver > 0
              && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" label="지연" class="blinking" />
          </span>
          <span v-if="props.row.approvalStatusCd === 'ASC9999999'">
            👉 {{props.row.targetDate}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          ibmTaskTypeCd: null,
          actionCompleteRequestDates: [],
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        improveRequestDeptCd: '',
        actionDeptCd: '',
        actionFlag: 'D',
        overFlag: 'N',
        ibmClassCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: '진행단계',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            label: '요청부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청정보',
            align: 'left',
            style: 'width:200px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '지연 결재',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionUserNames',
            field: 'actionUserNames',
            label: '조치자',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.printUrl = selectConfig.sop.ibm.improve.print.url
      // code setting
      // list setting
      if (this.popupParam.ibmTaskTypeCd ) {
        this.searchParam.plantCd = (!this.popupParam.plantCd || this.popupParam.plantCd.length > 3 ? null : this.popupParam.plantCd);
        this.searchParam.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
        this.searchParam.actionCompleteRequestDates = this.popupParam.actionCompleteRequestDates[0] + '01';
        this.searchParam.actionDeptCd = '';
      }

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addEtcImpr() {
      this.linkClick()
    },
    linkClick(row, col, index) {
      if (row.ibmClassCd == 'IC00000005') {// 즉시조치
        this.popupOptions.title = '즉시조치';
        this.popupOptions.isFull = false;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else {
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
        };
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    imprResultPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = '개선 결과 보고서_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
